import React from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { StaticImage } from 'gatsby-plugin-image'

import { useSiteMetadata } from '../../utils/hooks'
import { Wrapper, Links, Item, SocialMediaLinks, LowerBar } from './styles'
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram'
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF'
import { FaYoutube } from '@react-icons/all-files/fa/FaYoutube'

const Footer = ({ color = 'white' }) => {
  const { title, secondaryNav, socialLinks } = useSiteMetadata()

  return (
    <>
      <Wrapper>
        <StaticImage
          src="../../images/Logo_nighttec_footer.png"
          placeholder="tracedSVG"
          layout="fixed"
          alt="NightTec Logo"
          height={40}
        />
        <Links>
          <h3>
            <Trans>Information</Trans>
          </h3>
          {secondaryNav.map(({ name, link }) => (
            <Item to={link} key={name} color={color}>
              <Trans>{name}</Trans>
            </Item>
          ))}
        </Links>
        <Links>
          <h3>Social Media</h3>
          <SocialMediaLinks>
            {socialLinks.map(({ name, link }) => {
              return (
                <OutboundLink
                  href={link}
                  key={name}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {name === 'Instagram' && (
                    <FaInstagram color={color} size="28" />
                  )}
                  {name === 'Facebook' && (
                    <FaFacebookF color={color} size="28" />
                  )}
                  {name === 'YouTube' && <FaYoutube color={color} size="28" />}
                </OutboundLink>
              )
            })}
          </SocialMediaLinks>
        </Links>
        <Links color={color}>
          <h3>
            <Trans>Contact Us</Trans>
          </h3>
          <span>
            <Trans>Mail</Trans>:{' '}
            <a href="mailto:info@nighttec.net">info@NightTec.net</a>
          </span>
          <span>
            <Trans>Phone</Trans>: +49 176 462 921 69
          </span>
        </Links>
      </Wrapper>
      <LowerBar>
        © {new Date().getFullYear()} {title} |{' '}
        <Trans>All Rights Reserved</Trans>
      </LowerBar>
    </>
  )
}

export default Footer
