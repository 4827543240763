import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { colors, breakpoints } from '../../utils/styles'

export const OuterWrapper = styled.nav`
  background-color: black;
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
  z-index: 40;
`

export const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const MenuBar = styled.div`
  position: relative;
  z-index: 199;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 2.5rem;
  align-items: center;

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const LogoWrapper = styled.div`
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  z-index: 20;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  width: 100%;

  @media (min-width: ${breakpoints.l}px) {
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
`

export const MenuItem = styled(Link)`
  color: ${({ color }) => (color ? color : 'white')};
  text-decoration: none;
  font-size: 1.2rem;
  margin-left: 1.45rem;
  margin-right: 1.45rem;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  display: none;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;

  &:active {
    color: white;
  }

  &:hover {
    color: white;
  }

  @media (min-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const Line = styled.hr`
  width: 100%;
  border: 0.5pt solid ${({ color }) => (color ? color : 'black')};
  background-color: black;

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const LogoWrapepr = styled.span`
  position: relative;
  z-index: 199;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  opacity: ${({ logoVisible }) => (logoVisible ? '1' : '0')};

  animation: 1s ease-out 0s 1 slideInFromTop;

  @keyframes slideInFromTop {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const HamburgerWrapper = styled.span`
  z-index: 200;
  cursor: pointer;
  color: white;

  @media (min-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const GridLeft = styled.div`
  grid-area: left;
`

export const GridRight = styled.div`
  grid-area: right;
`

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
`

export const CartCounter = styled.span`
  background-color: white;
  color: #663399;
  border-radius: 20px;
  padding: 0 10px;
  font-size: 1.2rem;
  float: right;
  margin: -10px;
  z-index: 22;
`

export const CartWrapper = styled.div`
  justify-self: flex-end;
  position: relative;
  height: 32px;
  width: 32px;
  cursor: pointer;
  color: ${({ color }) => (color ? color : 'black')};
`

export const CartCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primaryColor};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: -4px;
  left: -4px;
  font-size: 10px;
  text-align: center;
  color: ${colors.colorOnPrimary};
`

export const LanguageSwitchter = styled.ul`
  display: flex;
  list-style: none;

  & > li {
    margin-left: 2.5rem;
  }

  & > li:first-of-type {
    margin-left: 0;
  }
`

export const InfoBanner = styled.div`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  background-color: ${colors.brightSun};

  svg {
    cursor: pointer;
  }

  @media (max-width: ${breakpoints.s}px) {
    & > :first-of-type {
      margin-right: 0.5rem;
    }
  }
`
