import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { breakpoints, colors, fontSizes } from '../../utils/styles'
import bg from '../../images/bg-pattern.jpg'

export const Wrapper = styled.footer`
  display: flex;
  padding: 2.5rem;
  padding-bottom: 1.45rem;
  justify-content: space-between;
  #background-image: url('${bg}');
  background-color: black;

  @media (max-width: ${breakpoints.s}px) {
    display: flex;
    align-items: center;
    flex-direction: column;

    & > :first-of-type {
      margin-bottom: 2.5rem;
    }
  }
`

export const Links = styled.div`
  color: ${({ color }) => (color ? color : 'white')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;

  h3 {
    margin-top: 0;
  }

  a {
    color: white;
    text-decoration: none;
  }

  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 2rem;
    align-items: center;
  }
`

export const Item = styled(Link)`
  color: ${({ color }) => (color ? color : 'white')};
  line-height: 1.563;
  font-size: 16px;
  text-decoration: none;
  margin-bottom: 0.25rem;
`

export const SocialMediaLinks = styled.span`
  list-style: none outside;
  margin: 0;
  padding: 0;

  & > * {
    margin-right: 1rem;
  }
`

export const Cards = styled.div`
  text-align: right;

  svg {
    ${({ color }) => (color ? color : 'black')};
    margin-left: 0.5rem;
  }

  @media (max-width: ${breakpoints.s}px) {
    text-align: center;
  }
`

export const LowerBar = styled.div`
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: ${fontSizes.sm}};
`
