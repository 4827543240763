import React, { useContext } from 'react'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'

import LayoutContext from './../../../context/LayoutContext'
import { useSiteMetadata } from '../../../utils/hooks'
import {
  Wrapper,
  ItemsList,
  PrimaryNavItem,
  SecondaryNavItem,
  LanguageSwitchter,
} from './styles'
import { GermanyFlag, USAFlag } from '../../ui/icons'

const MobileNav = ({ visible }) => {
  const { toggleMobileNav } = useContext(LayoutContext)
  const { primaryNav, secondaryNav } = useSiteMetadata()
  const { languages, originalPath, navigate } = useI18next()
  const { t } = useTranslation()

  const handleClick = link => {
    toggleMobileNav()
    navigate(link)
  }

  const PrimaryNav = primaryNav
    ? primaryNav.map(({ name, link }) => (
        <PrimaryNavItem key={name} onClick={() => handleClick(link)}>
          {t(name)}
        </PrimaryNavItem>
      ))
    : null

  const SecondaryNav = secondaryNav
    ? secondaryNav.map(({ name, link }) => (
        <SecondaryNavItem key={name} onClick={() => handleClick(link)}>
          {t(name)}
        </SecondaryNavItem>
      ))
    : null

  return (
    <Wrapper visible={visible}>
      <ItemsList>
        {PrimaryNav}
        {SecondaryNav}
      </ItemsList>
      <LanguageSwitchter>
        {languages.map(lng => (
          <li key={lng}>
            <Link
              to={originalPath}
              onClick={() => toggleMobileNav()}
              language={lng}
            >
              {lng === 'de' && <GermanyFlag height={16} />}
              {lng === 'en' && <USAFlag height={16} />}
            </Link>
          </li>
        ))}
      </LanguageSwitchter>
    </Wrapper>
  )
}

export default MobileNav
