import styled from '@emotion/styled'
import { colors } from '../../../utils/styles'

export const Wrapper = styled.div`
  visibility: ${({ visible }) => (visible ? 'block' : 'hidden')};
  z-index: 30;
  position: fixed;
  top: 0;
  height: ${({ visible }) => (visible ? '100%' : '0')};
  width: 100%;
  background-color: black;
  overflow-x: hidden;
  white-space: nowrap;
  transition: 0.5s;
`

export const ItemsList = styled.ul`
  list-style-type: none;
  margin: 5rem 0;
  padding: 0;
`

export const PrimaryNavItem = styled.li`
  font-weight: 300;
  line-height: 1.6;
  font-size: 1.5rem;
  margin-bottom: 0;
  padding: 15px 1.45rem 15px;
  position: relative;
  cursor: pointer;
  color: ${colors.androidGreen};

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 1.45rem;
    right: 1.45rem;
    border-bottom: 1px solid #343535;
  }
`

export const SecondaryNavItem = styled.li`
  color: ${colors.androidGreen};
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 0;
  padding: 15px 1.45rem 15px;
  position: relative;
  cursor: pointer;
`

export const LanguageSwitchter = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 1.45rem;

  & > li {
    margin-left: 2.45rem;
  }

  & > li:first-of-type {
    padding: 0;
    margin-left: 0;
  }
`
