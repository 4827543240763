import React, { useContext, useState, useEffect, Fragment } from 'react'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'
import { AiOutlineWarning } from '@react-icons/all-files/ai/AiOutlineWarning'

import LayoutContext from '../../context/LayoutContext'
import StoreContext from '../../context/StoreContext'
import {
  useGetSettings,
  useSessionStorage,
  useSiteMetadata,
} from '../../utils/hooks'
import { colors } from '../../utils/styles'
import {
  USAFlag,
  GermanyFlag,
  CartIcon,
  CloseIcon,
  HamburgerIcon,
} from '../ui/icons'
import MobileNav from './MobileNav'
import {
  OuterWrapper,
  InnerWrapper,
  MenuBar,
  MenuItem,
  LogoWrapper,
  LanguageSwitchter,
  InfoBanner,
  HamburgerWrapper,
  CartWrapper,
} from './styles'

const countQuantity = ({ lineItems = [] }) => {
  let quantity = 0
  lineItems.forEach(item => (quantity += item.quantity))
  return quantity
}

const Navigation = ({ color }) => {
  const { checkout } = useContext(StoreContext)
  const { mobileNavVisible, toggleMobileNav, setMobileNavVisible } =
    useContext(LayoutContext)
  const { showMessage, infoMessageDe, infoMessageEn } = useGetSettings()
  const [infoBar, setInfoBar] = useSessionStorage('show_info_bar', true)
  const [quantity, setQuantity] = useState(countQuantity(checkout))
  const [navBarColor, setNavBarColor] = useState(color)
  const { primaryNav } = useSiteMetadata()
  const { languages, originalPath, language } = useI18next()
  const { t } = useTranslation()

  useEffect(() => {
    setQuantity(countQuantity(checkout))
  }, [checkout])

  useEffect(() => {
    if (mobileNavVisible) {
      setNavBarColor('white')
    } else {
      setNavBarColor(color)
    }
  }, [mobileNavVisible, color])

  const hideInfoBanner = () => setInfoBar(false)

  return (
    <>
      <MobileNav visible={mobileNavVisible} />
      <OuterWrapper>
        <InnerWrapper>
          <LogoWrapper>
            <StaticImage
              src="../../images/Logo_nighttec.png"
              alt="NightTec Logo"
              onClick={() => {
                setMobileNavVisible(false)
                navigate('/')
              }}
              layout="constrained"
              placeholder="tracedSVG"
              height={40}
            />
            {mobileNavVisible ? (
              <HamburgerWrapper>
                <CloseIcon onClick={toggleMobileNav} />
              </HamburgerWrapper>
            ) : (
              <HamburgerWrapper>
                <HamburgerIcon onClick={toggleMobileNav} />
              </HamburgerWrapper>
            )}
          </LogoWrapper>
          <MenuBar>
            {primaryNav
              ? primaryNav.map(({ name, link }, i) => {
                  return (
                    <Fragment key={name}>
                      <MenuItem
                        to={link}
                        color={colors.androidGreen}
                        activeStyle={{
                          color: 'white',
                        }}
                      >
                        {t(name)}
                      </MenuItem>
                    </Fragment>
                  )
                })
              : null}
            <Link to="/cart">
              <CartWrapper color={colors.androidGreen}>
                <CartIcon as="button" count={quantity} />
              </CartWrapper>
            </Link>
            <LanguageSwitchter>
              {languages.map(lng => (
                <li key={lng}>
                  <Link to={originalPath} language={lng}>
                    {lng === 'de' && <GermanyFlag height={16} />}
                    {lng === 'en' && <USAFlag height={16} />}
                  </Link>
                </li>
              ))}
            </LanguageSwitchter>
          </MenuBar>
        </InnerWrapper>
        {infoBar && showMessage && (
          <InfoBanner visible={infoBar}>
            <AiOutlineWarning size={24} />
            <span>{language === 'de' ? infoMessageDe : infoMessageEn}</span>
            <CloseIcon onClick={hideInfoBanner} as="button" />
          </InfoBanner>
        )}
      </OuterWrapper>
    </>
  )
}

export default Navigation
